import './index.css'
import { Analytics } from '@vercel/analytics/react'
import { PiGearLight, PiQuestionLight, PiChartBarLight, PiUserCircleLight } from 'react-icons/pi'
import { IconContext } from 'react-icons'
import { useState, useRef, useEffect, useMemo } from 'react'
import JapaneseHome from './assets/backgrounds/JapaneseHome.jpg'
import JapaneseHomeSmall from './assets/backgrounds/JapaneseHomeSmall.jpg'
import DesertSunset from './assets/backgrounds/DesertSunset.jpg'
import DesertSunsetSmall from './assets/backgrounds/DesertSunsetSmall.jpg'
import Shibuya from './assets/backgrounds/Shibuya.jpg'
import ShibuyaSmall from './assets/backgrounds/ShibuyaSmall.jpg'
import WinterForest from './assets/backgrounds/WinterForest.jpg'
import WinterForestSmall from './assets/backgrounds/WinterForestSmall.jpg'
import WinterMountain from './assets/backgrounds/WinterMountain.jpg'
import WinterMountainSmall from './assets/backgrounds/WinterMountainSmall.jpg'
import NightMoutains from './assets/backgrounds/NightMountains.jpg'
import NightMoutainsSmall from './assets/backgrounds/NightMountainsSmall.jpg'
import Timer from './components/timer'
//import PhotoCredit from "./components/photoCredit.jsx"
import Settings from './components/settings'
import StatsPage from './components/statsPage.jsx'
import HelpPage from './components/helpPage.jsx'
import AuthPage from './components/authPage.jsx'
import SettingsContent from './components/settingsContent'
import timerWorkerScript from './scripts/timerWorker.js'
import breakFinishAudioClip from './assets/sounds/complete.mp3'
import workFinishAudioClip from './assets/sounds/lowHighChime.mp3'
import buttonClickAudioClip from './assets/sounds/buttonClick.mp3'
import campfireAudioLoop from './assets/sounds/campfireLoop.mp3'
import windAudioLoop from './assets/sounds/windLoop.mp3'
import rainAudioLoop from './assets/sounds/rainLoop.mp3'
import { supabase } from './scripts/supabaseClient.js'

// App Setups
function App() {
	// Default States
	const [activePage, setActivePage] = useState('Timer')
	const [cycleNumber, setCycleNumber] = useState(1)
	const [timerRunning, setTimerRunning] = useState(false)
	const [imageLoaded, setImageLoaded] = useState(false)
	const [session, setSession] = useState(null)
	const permission = useRef(null)
	const subscription = useRef(null)
	const todayDate = useRef(null)

	// Refernces for cookies
	const workingTimeCompleted = useRef(0)
	const workingCyclesCompleted = useRef(0)

	// Audio and Webworker Definitions
	const breakFinishAudio = useRef(null)
	const workFinishAudio = useRef(null)
	const buttonClickAudio = useRef(null)
	const fireAudio = useRef(null)
	const windAudio = useRef(null)
	const rainAudio = useRef(null)

	// Reference for webworker
	const timerWorker = useRef(null)

	// Themes
	const backgrounds = useMemo(
		() => [
			{
				name: ' Shibuya',
				image: Shibuya,
				smallImage: ShibuyaSmall,
				photographer: 'Mos Design',
				portfolio: 'https://unsplash.com/@mosdesign',
			},
			{
				name: ' Winter Mountain',
				image: WinterMountain,
				smallImage: WinterMountainSmall,
				photographer: 'EberHard Grossgasteiger',
				portfolio: 'https://unsplash.com/@eberhardgross',
			},
			{
				name: ' Japanese Home',
				image: JapaneseHome,
				smallImage: JapaneseHomeSmall,
				photographer: 'Marek Okon',
				portfolio: 'https://unsplash.com/@marekokon',
			},

			{
				name: ' Desert Sunset',
				image: DesertSunset,
				smallImage: DesertSunsetSmall,
				photographer: 'Alexander Psiuk',
				portfolio: 'https://unsplash.com/@alexdeloy',
			},
			{
				name: ' Winter Forest',
				image: WinterForest,
				smallImage: WinterForestSmall,
				photographer: 'Joyce G',
				portfolio: 'https://unsplash.com/@joyce_',
			},
			{
				name: ' Night Mountains',
				image: NightMoutains,
				smallImage: NightMoutainsSmall,
				photographer: 'Claudio Schwarz',
				portfolio: 'https://unsplash.com/@purzlbaum',
			},
		],
		[]
	)

	// Theme Definitions
	const themes = [
		{
			name: ' Default',
			buttonBackgroundColor: 'bg-gray-100',
			buttonBorderColor: 'border-gray-300',
			timerColor: '#e2e8f0',
			timerTextColor: '#1e293b',
			textColorHover: 'hover:text-gray-400',
			progressBarColor: '#60a5fa',
		},
		{
			name: ' Coffee',
			buttonBackgroundColor: 'bg-gray-100',
			buttonBorderColor: 'border-gray-200',
			timerColor: '#fef3c7',
			timerTextColor: '#1e293b',
			textColorHover: 'hover:text-gray-400',
			progressBarColor: '#92400e',
		},
		{
			name: ' Indigo',
			buttonBackgroundColor: 'bg-gray-100',
			buttonBorderColor: 'border-gray-200',
			timerColor: '#a5b4fc',
			timerTextColor: '#f8fafc',
			textColorHover: 'hover:text-gray-300',
			progressBarColor: '#6366f1',
		},
		/*
        {
            name: " Night",
            buttonBackgroundColor: "bg-gray-300",
            buttonBorderColor: "border-gray-400",
            timerColor: "#475569",
            timerTextColor: "#1e293b",
            textColorHover: "hover:text-gray-400",
            progressBarColor: "#60a5fa",
        },
        */
	]

	// Time formatting Function
	const formatTime = (timeRemaining) => {
		let minutes = Math.floor(timeRemaining / 60)
		let seconds = timeRemaining % 60
		let timerMinutes
		let timerSeconds

		// Format the minutes and seconds with leading zeros if necessary
		if (minutes < 10) {
			timerMinutes = `0${minutes}`
		} else {
			timerMinutes = minutes
		}

		if (seconds < 10) {
			timerSeconds = `0${seconds}`
		} else {
			timerSeconds = seconds
		}

		// Combine minutes and seconds into the final display format
		const timerDisplay = `${timerMinutes}:${timerSeconds}`

		return timerDisplay
	}

	const sendTimeWorkerMessage = (runningBoolean, timeRemaining) => {
		// Post message to the worker
		timerWorker.current.postMessage({
			timerRunning: runningBoolean,
			timeRemaining: timeRemaining,
		})
	}

	// Checking Local Storage Function
	const checkLocalStorage = (checkFor, defaultValue) => {
		const checker = localStorage.getItem(checkFor)
		return checker !== null ? JSON.parse(checker) : defaultValue
	}

	// Local Storage States
	const [showTabTimer, setTabTimer] = useState(() => {
		return checkLocalStorage('showTabTimer', true)
	})

	const [workingTime, setWorkingTime] = useState(() => {
		return checkLocalStorage('workingTime', 25 * 60)
	})

	const [shortBreakTime, setShortBreakTime] = useState(() => {
		return checkLocalStorage('shortBreakTime', 5 * 60)
	})

	const [longBreakTime, setLongBreakTime] = useState(() => {
		return checkLocalStorage('longBreakTime', 15 * 60)
	})

	const [backgroundImageIndex, setBackgroundImageIndex] = useState(() => {
		return checkLocalStorage('backgroundImageIndex', 0)
	})

	const [themeIndex, setThemeIndex] = useState(() => {
		return checkLocalStorage('themeIndex', 0)
	})

	// Register the service worker
	useEffect(() => {
		// Check if the browser supports service workers
		if ('serviceWorker' in navigator) {
			window.addEventListener('load', async () => {
				try {
					const swRegistration = await navigator.serviceWorker.register('./sw.js')
					console.log('Service worker registration succeeded:', swRegistration)

					const pushManager = swRegistration.pushManager

					if (!pushManager) {
						console.warn('Push Manager not available')
						return
					}

					let permissionState = await pushManager.permissionState()
					if (permissionState === 'prompt') {
						console.log('Permission prompt')
						permission.current = permissionState
					} else if (permissionState === 'granted') {
						console.log('Permission granted')
						permission.current = permissionState
						subscription.current = await pushManager.getSubscription()
						console.log('Push subscription', subscription.current)
					} else {
						console.log('Permission denied')
						permission.current = permissionState
					}
				} catch (e) {
					console.log('Error registering service worker', e)
				}
			})
		} else {
			console.log("Can't load service worker")
		}
	}, [])

	useEffect(() => {
		// Initialization logic
		const today = new Date()
		const year = today.getFullYear()
		const month = String(today.getMonth() + 1).padStart(2, '0') // Month is 0-indexed
		const day = String(today.getDate()).padStart(2, '0')

		// Setting refs
		todayDate.current = `${year}-${month}-${day}`
		timerWorker.current = new Worker(timerWorkerScript)
		breakFinishAudio.current = new Audio(breakFinishAudioClip)
		workFinishAudio.current = new Audio(workFinishAudioClip)
		buttonClickAudio.current = new Audio(buttonClickAudioClip)
		fireAudio.current = new Audio(campfireAudioLoop)
		windAudio.current = new Audio(windAudioLoop)
		rainAudio.current = new Audio(rainAudioLoop)

		// Cleanup function
		return () => {
			timerWorker.current.terminate()
		}
	}, []) // Empty dependency array, so this runs only once on mount.

	useEffect(() => {
		// Authentication state change listener
		const { subscription } = supabase.auth.onAuthStateChange(async (_event, session) => {
			if (_event === 'SIGNED_IN' || _event === 'SIGNED_OUT' || _event === 'INITIAL_SESSION') {
				setSession(session)
			}
		})

		// Cleanup: Unsubscribe when component unmounts or auth state changes
		return () => {
			subscription?.unsubscribe()
		}
	}, []) // Empty dependency array, so this runs only once on mount.

	useEffect(() => {
		// Initialization logic
		// Fetch initial study data from the backend
		const fetchStudyData = async () => {
			try {
				const { data, error } = await supabase
					.from('study_sessions')
					.select('minutes_studied')
					.eq('user_id', session.user.email)
					.eq('study_date', todayDate.current)

				if (data && data.length > 0) {
					workingTimeCompleted.current = data[0].minutes_studied
				}

				if (error) {
					console.error('Error fetching study log:', error)
				}
			} catch (err) {
				console.error('Unexpected error:', err)
			}
		}

		if (session && todayDate.current) {
			fetchStudyData()
		}
	}, [session, cycleNumber])

	// Image Loading Handler
	useEffect(() => {
		setImageLoaded(false)
		const img = new Image()
		img.src = backgrounds[backgroundImageIndex].image
		img.alt = backgrounds[backgroundImageIndex].name
		img.onload = () => {
			setImageLoaded(true)
		}

		return () => {
			setImageLoaded(false)
		}
	}, [backgroundImageIndex, backgrounds])

	return (
		<div className='h-screen w-screen'>
			<SettingsContent.Provider
				value={{
					showTabTimer,
					workingTime,
					shortBreakTime,
					longBreakTime,
					cycleNumber,
					timerWorker,
					breakFinishAudio,
					workFinishAudio,
					buttonClickAudio,
					fireAudio,
					windAudio,
					rainAudio,
					backgroundImageIndex,
					themeIndex,
					backgrounds,
					themes,
					workingTimeCompleted,
					workingCyclesCompleted,
					session,
					todayDate,
					timerRunning,
					permission,
					subscription,
					setActivePage,
					formatTime,
					setTabTimer,
					setWorkingTime,
					setShortBreakTime,
					setLongBreakTime,
					setCycleNumber,
					setBackgroundImageIndex,
					setThemeIndex,
					setSession,
					setTimerRunning,
					sendTimeWorkerMessage,
				}}
			>
				<div className='flex flex-col top-0 absolute w-full z-10'>
					<div className='flex flex-row justify-end min-h-12'>
						<IconContext.Provider value={{ className: 'topBarButton' }}>
							<PiUserCircleLight
								className='size-12'
								alt='Log In'
								onClick={() => {
									if (activePage !== 'AuthPage') {
										setActivePage('AuthPage')
									} else {
										setActivePage('Timer')
									}
								}}
							/>
						</IconContext.Provider>

						<IconContext.Provider value={{ className: 'topBarButton' }}>
							<PiQuestionLight
								className='size-12'
								alt='Question Mark Icon for Questions'
								onClick={() => {
									if (activePage !== 'HelpPage') {
										setActivePage('HelpPage')
									} else {
										setActivePage('Timer')
									}
								}}
							/>
						</IconContext.Provider>
						<span className='flex grow'></span>
						<IconContext.Provider value={{ className: 'topBarButton' }}>
							<PiChartBarLight
								className='size-12 '
								alt='Chart Icon for Statistics'
								onClick={() => {
									if (activePage !== 'Stats') {
										setActivePage('Stats')
									} else {
										setActivePage('Timer')
									}
								}}
							/>
						</IconContext.Provider>
						<IconContext.Provider value={{ className: 'topBarButton' }}>
							<PiGearLight
								className='size-12 '
								alt='Gear Icon for Settings'
								onClick={async () => {
									if (activePage !== 'Settings') {
										setActivePage('Settings')
									} else {
										setActivePage('Timer')
									}

									setTimerRunning(false)
									sendTimeWorkerMessage(false, null)
								}}
							/>
						</IconContext.Provider>
					</div>
				</div>

				<div
					className={
						'bg-no-repeat bg-cover bg-center h-full w-full overscroll-none  flex flex-col justify-center items-center'
					}
					style={{
						backgroundImage: imageLoaded
							? `url(${backgrounds[backgroundImageIndex].image})`
							: `url(${backgrounds[backgroundImageIndex].smallImage}`,
						backdropFilter: imageLoaded ? 'none' : `blur(16px)`,
					}}
				>
					<Timer />
					{activePage === 'AuthPage' ? <AuthPage /> : null}
					{activePage === 'Settings' ? <Settings /> : null}
					{activePage === 'Stats' ? <StatsPage /> : null}
					{activePage === 'HelpPage' ? <HelpPage /> : null}
				</div>
			</SettingsContent.Provider>
			<Analytics />
		</div>
	)
}

export default App
