import SettingsContent from './settingsContent'
import { useContext, useEffect, useState } from 'react'

const HelpPage = () => {
	const settings = useContext(SettingsContent)
	const [isMounted, setIsMounted] = useState(false)

	// Trigger the slide-in animation on component mount
	useEffect(() => {
		// Delay to ensure the component mounts first, then triggers animation
		const timeout = setTimeout(() => {
			setIsMounted(true)
		}, 10) // Small delay, e.g., 10ms, to ensure transition triggers

		return () => {
			clearTimeout(timeout)
			setIsMounted(false)
		}
	}, [])

	return (
		<div className='w-screen h-screen bg-slate-300 bg-opacity-70 absolute flex flex-col items-center justify-center'>
			<div
				className={`transform transition-transform duration-500 ease-out ${
					isMounted ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'
				} bg-slate-200 w-4/5 h-fit flex flex-col shadow-md rounded-lg p-3 justify-center items-center space-y-2`}
				style={{
					backgroundColor: `${settings.themes[settings.themeIndex].timerColor}`,
				}}
			>
				<div className='flex flex-row w-full'>
					<div className='w-1/2 h-full flex justify-center items-center'>
						<ul
							style={{
								color: `${settings.themes[settings.themeIndex].timerTextColor}`,
							}}
						>
							<p className='text-xl font-semibold'>Written by:</p>
							<li>
								<a
									href='https://github.com/ToCans'
									className={settings.themes[settings.themeIndex].textColorHover}
								>
									Tom
								</a>
							</li>
						</ul>
					</div>

					<div className='w-1/2 h-full flex justify-center items-center'>
						<ul
							style={{
								color: `${settings.themes[settings.themeIndex].timerTextColor}`,
							}}
						>
							<p className='text-xl font-semibold'>Images from:</p>
							<li>
								<a
									href='https://unsplash.com/@alexdeloy'
									className={settings.themes[settings.themeIndex].textColorHover}
								>
									Alexander Psiuk
								</a>
							</li>
							<li>
								<a
									href='https://unsplash.com/@eberhardgross'
									className={settings.themes[settings.themeIndex].textColorHover}
								>
									EberHard Grossgasteiger
								</a>
							</li>
							<li>
								<a
									href='https://unsplash.com/@mosdesign'
									className={settings.themes[settings.themeIndex].textColorHover}
								>
									Mos Design
								</a>
							</li>
							<li>
								<a
									href='https://unsplash.com/@marekokon'
									className={settings.themes[settings.themeIndex].textColorHover}
								>
									Marek Okon
								</a>
							</li>
							<li>
								<a
									href='https://unsplash.com/@joyce_'
									className={settings.themes[settings.themeIndex].textColorHover}
								>
									Joyce G
								</a>
							</li>
							<li>
								<a
									href='https://unsplash.com/@purzlbaum'
									className={settings.themes[settings.themeIndex].textColorHover}
								>
									Claudio Schwarz
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div className={`h-1 w-full opacity-5 rounded-xl bg-slate-800`} />
				<div
					style={{
						color: `${settings.themes[settings.themeIndex].timerTextColor}`,
					}}
				>
					Feedback or suggestions? Email:{' '}
					<a
						className={settings.themes[settings.themeIndex].textColorHover}
						href='mailto:cozystudyxyz@gmail.com'
					>
						cozystudyxyz@gmail.com
					</a>{' '}
				</div>
			</div>
		</div>
	)
}

export default HelpPage
