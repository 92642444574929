import SettingsContent from './settingsContent'
import Dashboard from './dashboard'
import { useContext, useEffect, useState } from 'react'

const StatsPage = () => {
	const settings = useContext(SettingsContent)
	const [isMounted, setIsMounted] = useState(false)

	// Trigger the slide-in animation on component mount
	useEffect(() => {
		// Delay to ensure the component mounts first, then triggers animation
		const timeout = setTimeout(() => {
			setIsMounted(true)
		}, 10) // Small delay, e.g., 10ms, to ensure transition triggers

		return () => {
			clearTimeout(timeout)
			setIsMounted(false)
		}
	}, [])

	// Gathering the ending for the day
	function getOrdinalSuffix(day) {
		if (day > 3 && day < 21) return 'th'
		switch (day % 10) {
			case 1:
				return 'st'
			case 2:
				return 'nd'
			case 3:
				return 'rd'
			default:
				return 'th'
		}
	}

	// Date Formatting
	function formatDate(date) {
		const options = { month: 'long', year: 'numeric', day: 'numeric' }
		const day = date.getDate()
		const dayWithSuffix = day + getOrdinalSuffix(day)

		return date.toLocaleDateString('en-US', options).replace(day, dayWithSuffix)
	}

	const currentDate = new Date()
	const formattedDate = formatDate(currentDate)

	return (
		<div className='w-screen h-screen bg-slate-300 bg-opacity-70 absolute flex flex-col items-center justify-center'>
			<div
				className={`transform transition-transform duration-500 ease-out ${
					isMounted ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'
				} bg-slate-200 xl:w-1/4 w-4/5 h-fit flex flex-col shadow-md rounded-lg p-2 justify-center items-center`}
				style={{
					backgroundColor: `${settings.themes[settings.themeIndex].timerColor}`,
				}}
			>
				<div className='w-full'>
					<p className='font-semibold'>{formattedDate}</p>
					{settings.session ? (
						<p>{settings.session.user.email}</p>
					) : (
						<div>
							<button
								className='underline hover:font-semibold'
								onClick={() => {
									settings.setActivePage('AuthPage')
								}}
							>
								Log in
							</button>{' '}
							to store study stats!
						</div>
					)}
				</div>

				{settings.session ? (
					<Dashboard />
				) : (
					<div className='h-full flex flex-col justify-center items-center select-none'>
						<div>
							<div className='flex flex-row text-center space-x-1 justify-center'>
								<p className='font-semibold'>Working Cycles Completed:</p>
								<p className='font-normal'>
									{settings.workingCyclesCompleted.current}
								</p>
							</div>
							<div className='flex flex-row text-center space-x-1'>
								<p className='font-semibold'>Working Minutes Completed:</p>
								<p className='font-normal'>
									{settings.workingTimeCompleted.current}
								</p>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default StatsPage
