import SettingsContent from './settingsContent'
import { useContext } from 'react'
import { IconContext } from 'react-icons'
import {
	PiPlayDuotone,
	PiPauseDuotone,
	PiSkipForwardDuotone,
	PiArrowCounterClockwise,
} from 'react-icons/pi'

const TimeButton = ({ purpose, timeRemaining, setTimeRemaining, setTimerRunning }) => {
	const settings = useContext(SettingsContent)

	const timeButtonClicked = () => {
		settings.buttonClickAudio.current.pause()
		settings.buttonClickAudio.current.currentTime = 0
		settings.buttonClickAudio.current.play()
	}

	const subscribeToPush = async () => {
		let swRegistration = await navigator.serviceWorker.getRegistration()

		if (!swRegistration) {
			console.log('Service worker not registered')
			return null
		}

		let pushManager = swRegistration.pushManager

		if (!pushManager) {
			console.log('No push manager')
			return
		}

		// Setting up subscription options
		let subscriptionOptions = {
			userVisibleOnly: true,
			applicationServerKey: process.env.REACT_APP_VAPID_PUBLIC_KEY,
		}

		try {
			let pushSubscription = await pushManager.subscribe(subscriptionOptions)
			settings.subscription.current = pushSubscription
		} catch (error) {
			console.log('Subscription error', error)
		}
	}

	// Start Button Handling
	if (purpose === 'Start') {
		return (
			<IconContext.Provider value={{ className: 'timerButton' }}>
				<PiPlayDuotone
					id='startButton'
					onClick={async () => {
						if (settings.permission.current === 'prompt') {
							await subscribeToPush()
						}

						settings.sendTimeWorkerMessage(true, timeRemaining)
						timeButtonClicked()
						setTimerRunning(true)
					}}
					className='size-8'
				/>
			</IconContext.Provider>
		)
	}
	// Pause Button Handling
	else if (purpose === 'Pause') {
		return (
			<IconContext.Provider value={{ className: 'timerButton' }}>
				<PiPauseDuotone
					id='pauseButton'
					onClick={async () => {
						if (settings.permission.current === 'prompt') {
							await subscribeToPush()
						}

						settings.sendTimeWorkerMessage(false, timeRemaining)
						timeButtonClicked()
						setTimerRunning(false)
					}}
					className='size-8'
				/>
			</IconContext.Provider>
		)
	}
	// Pause Button Handling
	else if (purpose === 'Restart') {
		return (
			<IconContext.Provider value={{ className: 'timerButton' }}>
				<PiArrowCounterClockwise
					id='restartButton'
					onClick={async () => {
						if (settings.permission.current === 'prompt') {
							await subscribeToPush()
						}
						settings.sendTimeWorkerMessage(false, null)
						timeButtonClicked()
						setTimerRunning(false)

						if (settings.cycleNumber % 8 === 0) {
							setTimeRemaining(settings.longBreakTime)
						} else if (settings.cycleNumber % 2 === 0) {
							setTimeRemaining(settings.shortBreakTime)
						} else {
							setTimeRemaining(settings.workingTime)
						}
					}}
					className='size-8'
				/>
			</IconContext.Provider>
		)
	}
	// Skip Button Handling
	else {
		return (
			<IconContext.Provider value={{ className: 'timerButton' }}>
				<PiSkipForwardDuotone
					id='skipButton'
					onClick={async () => {
						if (settings.permission.current === 'prompt') {
							await subscribeToPush()
						}
						settings.sendTimeWorkerMessage(false, null)
						timeButtonClicked()
						setTimerRunning(false)
						settings.setCycleNumber(settings.cycleNumber + 1)
					}}
					className='size-8'
				/>
			</IconContext.Provider>
		)
	}
}

export default TimeButton
