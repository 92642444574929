import SettingsContent from './settingsContent'
import TimePeriodRow from './timePeriodRow'
import MonthDashboard from './monthDashboard'
import YearDashboard from './yearDashboard'
import WeekDashboard from './weekDashboard'
import moment from 'moment'
import { supabase } from '../scripts/supabaseClient'
import { useContext, useState, useEffect, useRef } from 'react'
import { PiArrowLeft, PiArrowRight } from 'react-icons/pi'

const Dashboard = () => {
	// Setting Context used
	const settings = useContext(SettingsContent)
	const [dashboardData, setDashboardData] = useState([])
	const [loaded, setLoaded] = useState(false)
	const [timePeriod, setTimePeriod] = useState('Month')
	const [yearSelect, setYearSelect] = useState(moment().year())
	const [timePeriodLimit, setTimePeriodLimit] = useState(moment().month())
	const [timeWindowIndex, setTimeWindowIndex] = useState(moment().month())
	const timePeriodMinutes = useRef(0)

	useEffect(() => {
		// Resetting the Data
		//setDashboardData([])
		timePeriodMinutes.current = 0

		// Generating a Range of Dates for the period
		const generateDateRange = (startDate, endDate) => {
			// Array to hold all the dates
			const dateArray = []

			// Iterate over each day in the time frame
			let currentDate = startDate.clone()
			while (currentDate.isSameOrBefore(endDate)) {
				dateArray.push(currentDate.format('YYYY-MM-DD'))
				currentDate.add(1, 'days')
			}
			return dateArray
		}

		// Fetching the Data
		const fetchStudyData = async (startDate, endDate) => {
			setLoaded(false)
			try {
				const { data, error } = await supabase
					.from('study_sessions')
					.select('study_date, minutes_studied')
					.eq('user_id', settings.session.user.email)
					.gte('study_date', startDate) // Greater than or equal to start date
					.lte('study_date', endDate)

				if (data) {
					const generatedDates = generateDateRange(moment(startDate), moment(endDate))

					// Map over the generated dates
					const completeData = generatedDates.map((date) => {
						// Find if the date exists in the serverData
						const matchingData = data.find((item) => item.study_date === date)

						if (matchingData) {
							// Add the minutes_studied to the counter
							timePeriodMinutes.current += matchingData.minutes_studied
						}

						// Return the matching data if found, or create a default object with zero values
						return (
							matchingData || {
								study_date: date,
								minutes_studied: 0,
							}
						)
					})

					setDashboardData(completeData)
				}

				if (error) {
					console.error('Error fetching study log:', error)
				}
			} catch (err) {
				console.error('Unexpected error:', err)
			}
			setLoaded(true)
		}

		if (timePeriod === 'Week') {
			fetchStudyData(
				moment()
					.year(yearSelect)
					.isoWeek(timeWindowIndex)
					.startOf('isoWeek')
					.format('YYYY-MM-DD'),
				moment()
					.year(yearSelect)
					.isoWeek(timeWindowIndex)
					.endOf('isoweek')
					.format('YYYY-MM-DD')
			)
		} else if (timePeriod === 'Month') {
			fetchStudyData(
				moment([yearSelect, timeWindowIndex]).startOf('month').format('YYYY-MM-DD'),
				moment([yearSelect, timeWindowIndex]).endOf('month').format('YYYY-MM-DD')
			)
		} else if (timePeriod === 'Year') {
			fetchStudyData(
				moment([yearSelect]).startOf('year').format('YYYY-MM-DD'),
				moment([yearSelect]).endOf('year').format('YYYY-MM-DD')
			)
		} else {
			console.log('Error with time period')
		}
	}, [
		timePeriod,
		yearSelect,
		timeWindowIndex,
		settings.todayDate,
		settings.session.user.email,
		settings.workingTimeCompleted,
	])

	return (
		<div className='flex flex-col space-y-2 p-1 w-full'>
			<div className='w-full flex flex-col sm:flex-row items-center'>
				<TimePeriodRow
					timePeriod={timePeriod}
					setTimePeriod={setTimePeriod}
					setTimePeriodLimit={setTimePeriodLimit}
					setTimeWindowIndex={setTimeWindowIndex}
					setDashboardData={setDashboardData}
					setYearSelect={setYearSelect}
				/>
				<span className='flex flex-grow'></span>

				<div className='flex flex-row space-x-1 md:space-x-2 items-center'>
					<button>
						<PiArrowLeft
							className='size-4 mt-1 hover:stroke-slate-600'
							onClick={() => {
								if (timeWindowIndex > 0) {
									setTimeWindowIndex(timeWindowIndex - 1)
								}
							}}
						/>
					</button>
					<button>
						<PiArrowRight
							className='size-4 mt-1 hover:stroke-slate-600'
							onClick={() => {
								if (timeWindowIndex < timePeriodLimit) {
									setTimeWindowIndex(timeWindowIndex + 1)
								}
							}}
						/>
					</button>
				</div>
			</div>
			{timePeriod === 'Week' && loaded ? (
				<WeekDashboard
					timeWindowIndex={timeWindowIndex}
					timePeriodMinutes={timePeriodMinutes}
					dashboardData={dashboardData}
				/>
			) : null}
			{timePeriod === 'Month' && loaded ? (
				<MonthDashboard
					timeWindowIndex={timeWindowIndex}
					timePeriodMinutes={timePeriodMinutes}
					dashboardData={dashboardData}
				/>
			) : null}
			{timePeriod === 'Year' && loaded ? (
				<YearDashboard
					yearSelect={yearSelect}
					timePeriodMinutes={timePeriodMinutes}
					dashboardData={dashboardData}
				/>
			) : null}

			{!loaded ? <p className='text-center'>Loading ...</p> : null}
		</div>
	)
}

export default Dashboard
